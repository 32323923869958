.date-picker-container {
  width: 100%;
  margin-bottom: var(--standard-margin);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}
.date-item {
  width: var(--filter-width);
  text-align: center;
  color: var(--imago-light);
  border: var(--date-picker-border);
  line-height: var(--date-container-height);
  font-size: 13px;
  box-sizing: border-box;
  margin-left: var(--standard-margin);
  -webkit-transition: var(--date-picker-transition);
  transition: var(--date-picker-transition);
}

.date-item:first-child {
  margin-left: 0;
}

.selected-date-item {
  color: var(--imago-dark);
  font-size: 20px;
  background: var(--imago-light);
  flex-grow: 2;
}

.date-item:hover {
  background: var(--imago-light);
  color: var(--imago-dark);
  cursor: pointer;
}

@media only screen and (max-width: 1050px) {
  .selected-date-item {
    font-size: 13px;
  }
}

.date-item:first-child {
  border: var(--date-picker-border);
}

@media only screen and (max-width: 750px) {
  .date-item {
    font-family: "IBM Plex Sans Condensed", sans-serif;
    margin-left: 0;
    border-left: none;
  }
}
@media only screen and (max-width: 500px) {
  .date-item {
    line-height: var(--date-picker-mobile-height);
  }

  .date-item:first-child {
    border-left: none;
  }

  .date-item:last-child {
    border-right: none;
  }
}
