.culinary-container {
  margin-top: 40px;
  width: 100%;
}

.culinary-container img {
    width: 200px;
    height: auto;
    margin-top: 20px;
}

.culinary-container strong {
    font-size: 22px;
    color: var(--imago-light);
}

@media only screen and (max-width: 750px) {
  .culinary-container {
    width: 100%;
  }
}
