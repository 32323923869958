.subtitle-symposium {
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 30px;
  color: var(--imago-light-text);
}

.symposium-sponsors-container {
  margin: 0;
  margin-top: 20px;
}

.symposium-program-container ul {
  padding-left: 0px;
}

.symposium-program-container li {
  font-weight: bold;
  margin-bottom: 3px;
  list-style-type: none;
}

.symposium-program-container em {
  font-weight: normal;
}

@media only screen and (max-width: 500px) {
  .margin-on-mobile {
    width: var(--app-width);
    margin-left: auto;
    margin-right: auto;
  }
}
