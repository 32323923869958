.sponsors-container {
  display: grid;
  grid-gap: var(--standard-margin) var(--standard-margin);
  grid-template-columns: auto auto auto auto;
  margin-bottom: 40px;
  margin-top: 40px;
}

.sponsors-image-container {
  height: 100px;
  text-align: center;
}

.sponsors-container img {
  height: 100%;
}

.main-container > h1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.symposium-about-text {
  margin-top: 30px;
}

.sponsors_title {
  font-size: 25px;
  margin-top: 30px;
}

.sipk-sponsors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.sipk-sponsors img {
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  width: 25%;
}

.sipk-sponsors-text {
  font-weight: bold;
  margin-top: 15px;
}

#ceska {
  width: 250px;
  height: auto;
  margin-left: 20px;
}

#mizs {
  width: 35%;
}

@media only screen and (max-width: 1050px) {
  .sponsors-container {
    grid-gap: 0;
    grid-template-columns: auto auto auto;
  }
  .sponsors-container {
    display: none;
  }
  .sponsors_title {
    display: none;
  }
  #ceska {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .sipk-sponsors {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }
  .sipk-sponsors img {
    height: auto;
    width: 70%;
    padding: 20px;
  }

  #mizs {
    width: 70%;
  }
}
