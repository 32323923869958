.artist-square-container {
  width: 100%;
  padding-bottom: 100%;
  color: white;
  position: relative;
  cursor: pointer;
  background: var(--imago-dark);
  outline: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
}

.selected-container {
  background: var(--imago-light) !important;
  color: var(--dark-text-color);
}

.passed-container {
  background: #2b3a49;
}

.artist-square-container img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: var(--hover-transition-length);
}

.filter-img {
  z-index: 2;
  -webkit-filter: grayscale(1) contrast(1.5) brightness(0.3);
  filter: grayscale(1) contrast(1.5) brightness(0.3);
  mix-blend-mode: screen;
}

.no-filter-img {
  opacity: 0;
  z-index: 10;
  mix-blend-mode: normal;
}

.selected-filter-img {
  -webkit-filter: grayscale(1) contrast(1.2) brightness(1);
  filter: grayscale(1) contrast(1.2) brightness(1);
}

.artist-square-container:hover .no-filter-img {
  opacity: 1;
}

/* ie, edge */

.filter-img-ie-edge {
  filter: grayscale(1);
  z-index: 2;
}

.selected-filter-img-ie-edge {
  -webkit-filter: grayscale(1) contrast(1.2) brightness(0.5);
  filter: grayscale(1) contrast(1.2) brightness(0.5);
}

.overlay-ie-edge {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--imago-dark);
  z-index: 3;
  opacity: 0.6;
  transition: var(--hover-transition-length);
}

.overlay-ie-edge-selected {
  background: var(--imago-light) !important;
}

.overlay-ie-edge-passed {
  background: #3f3f3f;
}

.artist-square-container:hover .overlay-ie-edge {
  opacity: 0;
}

.artist-square-container:hover .artist-time-location {
  opacity: 0;
}
.artist-square-container:hover .artist-title {
  opacity: 0;
}

.artist-square-container:hover > .filter-img-ie-edge {
  filter: none;
}

.symposium-background {
  background: var(--dark-text-color);
}

/* Name, location, date */

.artist-title {
  position: absolute;
  z-index: 5;
  padding: 0px 15px 10px 15px;
  bottom: 0;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  font-weight: bold;
  word-wrap: break-word;
  box-sizing: border-box;
  text-overflow: ellipsis;
  display: block;
  max-height: 65%;
  width: 100%;
  transition: var(--hover-transition-length);
}

.artist-time-location {
  position: absolute;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  z-index: 5;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 0;
  padding: 5px 15px 0px 15px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  transition: var(--hover-transition-length);
}

.artist-time-location span {
  font-size: 25px;
  max-height: 50%;
  font-weight: bold;
}

/* mobile */

.mobile-artist-desc {
  display: flex;
  flex-direction: row;
  position: absolute;
  height: 100%;
  font-weight: bold;
  width: 100%;
  padding: 7px;
  box-sizing: border-box;
}

.mobile-artist-date {
  width: 20%;
  color: var(--imago-light);
}

.mobile-artist-name-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobile-artist-name-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-artist-location {
  display: flex;
}

.mobile-artist-location > img {
  width: 12px;
  height: 12px;
  margin-top: 4px;
}
.mobile-artist-location > span {
  margin-left: 16px;
}

@media only screen and (max-width: 500px) {
  .artist-square-container {
    width: 100%;
    padding-bottom: 25%;
  }

  .artist-square-container {
    outline: 1px solid var(--imago-light);
  }

  .artist-square-container:hover .no-filter-img {
    opacity: 0;
  }
}
