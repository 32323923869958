.filter-main {
  width: var(--filter-width);
  float: right;
  margin-bottom: var(--standard-margin);
  text-align: center;
  user-select: none;
  font-size: 13px;
}

.filter-holder {
  height: var(--filter-location-container-height);
  border: var(--filter-border-thickness) solid var(--imago-light);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.filter-label {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  line-height: var(--filter-location-container-height);
}

.filter-label div:first-child {
  position: absolute;
  width: 50%;
}

.filter-label div:last-child {
  position: absolute;
  width: 50%;
  margin-left: 50%;
}

.filter-handle {
  width: 50%;
  height: var(--filter-location-container-height);
  -webkit-transition: var(--toggle-button-transition);
  transition: var(--toggle-button-transition);
  background: var(--imago-light);
}

.selected {
  color: var(--imago-dark);
  font-weight: bold;
}

.time-selected {
  margin-left: 0;
  border-right: 2px solid var(--imago-light);
}

.locations-selected {
  margin-left: 50%;
  border-left: 2px solid var(--imago-light);
}

.unselected {
  color: var(--imago-light);
}

@media only screen and (max-width: 500px) {
  .filter-main {
    width: 100%;
    float: none;
  }

  .filter-holder {
    border-left: none;
    border-right: none;
    height: var(--filter-location-container-height);
  }

  .filter-label {
    line-height: var(--filter-location-container-height);
  }
}
