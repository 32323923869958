.button-container {
    width: var(--button-width);
    height: var(--button-height);
    background: var(--imago-light);
    text-align: center;
    line-height: var(--button-height);
    color: var(--imago-dark);
    -webkit-transition: var(--hover-transition-length);
    transition: var(--hover-transition-length);
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}

.button-container:hover {
    background: var(--imago-light-hover);
}