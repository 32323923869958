:root {
  /* app */
  --app-width: 950px;
  --logo-height: 60%;

  --menu-bar-height: 100px;
  --extended-bar-height: 60px;
  --mobile-menu-bar-height: 70px;
  --content-margin-header: 0px;
  --content-margin-footer: 20px;

  --link-transition-length: 0.2s;
  --hover-transition-length: 0.3s;
  --fade-in-out-transition: 0.5s;
  --date-picker-transition: 0.3s;
  --artist-collapsible-transition: 0.5s;
  --toggle-button-transition: 0.4s;

  --standard-font-size: 19px;
  --title-font-size: 47px;
  --title-font-size-mobile: 30px;

  --filter-location-container-height: 40px;
  --date-container-height: 40px;

  /* menu */
  --links-padding: 15px;
  --standard-margin: 15px;

  /* colors */
  --imago-dark: #0a305a;
  --imago-menu-bar: #052548;
  --imago-light: #01d5c7;
  --imago-light-hover: #009b90;
  --imago-light-text: #a0fcf6;
  --dark-text-color: #142b45;

  /* date picker */

  --date-picker-mobile-height: 35px;
  --date-picker-border: 1px solid var(--imago-light);

  /* toggle filter */
  --filter-width: 180px;
  --filter-border-thickness: 1px;

  /* button */
  --button-height: 28px;
  --button-width: 150px;

  /* footer */
  --footer-height: 200px;
  --footer-mobile-height: 180px;

  /* expanded-artist */
  --expanded-artist-height: 350px;
}

@media only screen and (max-width: 1200px) {
  :root {
    --app-width: 83%;
  }
}

@media only screen and (max-width: 1050px) {
  :root {
    --app-width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  :root {
    --app-width: 90%;
    --filter-location-container-height: 30px;
  }
  
}
