.news-component-container {
  margin-bottom: 30px;
}

.news-component-container a {
  -webkit-transition: var(--link-transition-length);
  transition: var(--link-transition-length);
}

.news-component-title {
  font-weight: bold;
  font-size: 30px;
  color: var(--imago-light);
  
}

.news-component-title a:hover {
  color: var(--imago-light-hover);
}

.news-component-content {
  font-size: var(--standard-font-size);
}
