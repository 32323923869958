.leaflet-container {
  height: 400px;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 500px) {
  .leaflet-container {
    height: 200px;
  }
}
