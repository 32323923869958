.artist-collapsible {
  width: 100%;
  background: var(--imago-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--imago-dark);
  font-weight: bold;
  transition: var(--artist-collapsible-transition);
}

.expanded {
  height: var(--expanded-artist-height);
  margin-bottom: var(--standard-margin);
}

.collapsed {
  height: 0px;
  opacity: 0;
  overflow: hidden;
}

.description-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.band-description-container {
  font-weight: normal;
  position: relative;
  font-size: 17px;
  height: 80px;
}

.band-description {
  position: absolute;
  bottom: 0;
  left: 0;
}

.description-container a {
  margin-top: 20px;
  font-weight: bold;
  color: var(--imago-dark);
  transition: var(--link-transition-length);
  -webkit-transition: var(--link-transition-length);
}

:root {
  --base: #0a305a;
  --bg-blend: screen;
  --blur: 0px;
  --fg-blend: multiply;
  --foreground: #01d5c7;
  --opacity: 1;
  --spacing: 1%;
}

.artist-image-ie {
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.artist-image-ie > img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: var(--expanded-artist-height);
}

.artist-image {
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--base);
}

.artist-image a {
  width: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  z-index: 10;
  height: 100%;
}

.artist-image::before {
  background-color: var(--foreground);
  bottom: 0;
  content: "";
  height: 100%;
  filter: brightness(1);
  left: 0;
  mix-blend-mode: var(--fg-blend);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.artist-image > img {
  width: 100%;
  height: var(--expanded-artist-height);
  -o-object-fit: cover;
  object-fit: cover;
  filter: grayscale(100%) contrast(1) brightness(1.2);
  mix-blend-mode: var(--bg-blend);
}

.expanded-artist-title {
  font-size: 30px;
  color: white;
}

.expanded-artist-country {
  color: white;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 20px;
}

.location {
  font-size: 32px;
}

.time {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 25px;
}

.description-container a:hover {
  color: white;
}

.exit-button {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  background: var(--imago-light);
  cursor: pointer;
  z-index: 99;
}
.exit-button img {
  height: 100%;
  width: 100%;
  padding: 13px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 500px) {
  .artist-collapsible {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transition: none;
    position: relative;
  }

  .artist-mobile-background {
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 0;
  }

  .artist-mobile-background > img {
    width: 100%;
    height: 100%;
    mix-blend-mode: screen;
    object-fit: cover;
    filter: grayscale(1) brightness(0.7);
  }

  .artist-mobile-background-color {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--imago-light);
  }

  .artist-mobile-desc {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    z-index: 100;
  }

  .expanded {
    height: 250px;
    margin-bottom: 0;
  }

  .ie-expanded {
    height: 200px;
  }

  .description-container {
    width: 100%;
    padding: 0;
    display: inline-block;
  }

  .artist-image {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    margin-bottom: 15px;
    box-sizing: border-box;
  }

  .artist-image > img {
    height: 200px;
  }

  .expanded-artist-title {
    font-size: 20px;
    color: var(--imago-dark);
    margin-bottom: 0;
  }

  .expanded-artist-country {
    color: var(--imago-dark);
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 0px;
  }
}
