.artist-page-title {
  font-size: 38px;
  font-weight: bold;
  font-family: "IBM Plex Serif", serif;
}

.artist-page-country {
  font-size: 30px;
  margin-bottom: 20px;
  color: var(--imago-light);
}

.artist-festivals {
  margin-bottom: 20px;
}

.content-container {
  width: 100%;
  display: flex;
}

.artist-description-container {
  color: var(--imago-light);
  width: 65%;
}

.artist-description-container a {
  text-decoration: underline;
  color: white;
  transition: var(--hover-transition-length);
}

.artist-description-container a:hover {
  color: var(--imago-light);
}


.artist-description-container > img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.desc-data-item {
  margin-bottom: 0px;
}

.desc-item {
  margin-bottom: 10px;
}

.artist-page-desc {
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.artist-desc-spacer {
  width: 100%;
  height: 14px;
}

.artist-desc-line {
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  background: var(--imago-light);
  opacity: 0.5;
}

.desc-expand {
  transition: var(--link-transition-length);
  cursor: pointer;
  margin-bottom: 0px;
}

.desc-expand:hover {
  color: white;
}

.artist-long-description {
  margin-top: 10px;
}

.location-time {
  font-size: 33px;
  font-weight: bold;
}

.artist-data-location {
  font-size: 33px;
  margin-top: 20px;
}

.members {
  margin-top: 30px;
  font-size: var(--standard-font-size);
  line-height: 30px;
}

.artist-data {
  width: 35%;
  padding: 0 0px 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.artist-data-item {
  padding-bottom: 10px;
}

#festival-logo {
  width: 17%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 500px) {
  .content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .artist-data {
    width: 100%;
    padding: 0;
  }

  .artist-description-container {
    width: 100%;
  }

  .artist-description-container img {
    height: 200px;
    margin-bottom: 15px;
  }

  .artist-page-title {
    font-size: 25px;
  }

  .artist-page-country {
    font-size: 20px;
  }

  .location-time {
    font-size: 20px;
    font-weight: bold;
  }

  .artist-data-location {
    font-size: 23px;
    margin-top: 10px;
  }

  .members {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
