.about-festival {
  width: 70%;
  color: var(--imago-light);
  font-size: 25px;
  line-height: 38px;
  margin-bottom: var(--standard-margin);
}

.about-festival > a {
  text-decoration: underline;
}

.publications > p {
  font-size: var(--title-font-size);
  font-weight: bold;
  color: var(--imago-light);
}

.publications .title {
  margin-top: 40px;
}

.statement-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: var(--standard-margin);
}

.publications img:hover {
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
}

.publications img {
  cursor: pointer;
  margin-right: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.logo-desc {
  width: 60%;
  font-size: var(--standard-font-size);
  margin-bottom: var(--standard-margin);
}
