.symposium-item-container {
  border-bottom: 3px solid var(--imago-light);
  margin-bottom: 20px;
}

.symposium-date-picker {
  margin-bottom: 30px;
}

.item-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: "IBM Plex Serif", serif;
}

.item-hour {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
  color: var(--imago-light-text);
}

.item-content {
  color: var(--imago-light);
  font-size: var(--standard-font-size);
}
