.locations-grid-container {
  margin-top: var(--standard-margin);
}

.remove-margin {
  margin: 0;
}

.all-locations-button {
  font-size: 14px;
  font-weight: bold;
  color: var(--imago-light);
  margin: 15px 0 15px 0;
  cursor: pointer;
}

.all-locations-button:hover {
  color: white;
}

@media only screen and (max-width: 500px) {
  .all-locations-button {
    margin-left: 10px;
  }
}
