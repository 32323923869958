.artist-grid-row {
  width: 100%;
  margin-bottom: var(--standard-margin);
  clear: both;
  display: grid;
  grid-gap: var(--standard-margin) var(--standard-margin);
  grid-template-columns: auto auto auto auto;
}

.location-container {
  width: 100%;
  height: var(--filter-location-container-height);
  line-height: calc(var(--filter-location-container-height) / 2);
  background: var(--imago-light);
  color: var(--imago-dark);
  margin-bottom: var(--standard-margin);
  font-size: 20px;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

@media only screen and (max-width: 1050px) {
  .artist-grid-row {
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (max-width: 750px) {
  .artist-grid-row {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 500px) {
  .artist-grid-row {
    grid-template-columns: auto;
    margin-bottom: 0px;
  }
  .location-container {
    line-height: var(--filter-location-container-height);
    margin-bottom: 0;
    padding: 0;
    padding-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background: white;
    font-size: 16px;
    text-align: start;
  }
}
