.footer {
  color: var(--imago-light);
  background: var(--imago-menu-bar);
  font-family: "IBM Plex Serif", serif;
  letter-spacing: 0.5px;
}

input {
  display: block;
  border: 2px solid;
  width: 100%;
  font-size: 13px;
  box-sizing: border-box;
  margin-bottom: 15px;
  color: var(--imago-light);
  background: var(--imago-dark);
  padding: 6px 6px;
}

.main-footer-container {
  width: var(--app-width);
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-financer {
  background: var(--imago-dark);
  padding-bottom: 30px;
  padding-top: 30px;
  font-family: "IBM Plex Sans", sans-serif;
}

.financer-container {
  display: flex;
  width: var(--app-width);
  margin: 0 auto;
  text-align: justify;
  color: white;
}

.financer-text {
  margin-left: 40px;
}

.financer-logo img {
  width: 254px;
}

.footer-column {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 45px 0 20px 0px;
  padding: 0px 15px 0px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid var(--imago-light);
}

.footer-column:first-child {
  padding: 0;
  margin-left: 0;
  border: none;
}

.footer-column:first-child img {
  width: 222px;
  min-height: 222px;
  display: block;
}

.footer-imago-info {
  margin-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  line-height: 25px;
  flex: 1;
  display: flex;
  font-size: 15px;
  width: 100%;
  flex-direction: column;
  word-wrap: break-word;
}

.footer-imago-info p {
  margin-top: 5px;
  margin-bottom: 0px;
}

.footer-imago-info div:nth-child(2) {
  flex: 30;
}

.footer-column:last-child {
  border: none;
  padding-right: 0;
}

.footer-column:nth-child(2) {
  padding-left: 0;
}

.footer-column > div:nth-child(2) {
  flex: 30;
}

.footer-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.media-title {
  color: white;
  padding-bottom: 15px;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: bold;
  border-bottom: 1px solid var(--imago-light);
  transition: var(--link-transition-length);
}

.donate-title {
  margin-bottom: 5px;
}

.media-title a {
  color: white;
  transition: var(--link-transition-length);
}
.media-title a:hover {
  color: var(--imago-light);
}

.footer-title:last-child {
  border-bottom: 1px solid var(--imago-light);
}

.copyright-footer-container {
  text-align: center;
  margin: 0 auto;
  font-size: 9.5px;
  margin-bottom: 20px;
  width: var(--app-width);
  text-align: start;
}

.footer-social-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-social-container img {
  margin: 10px 20px 0px 0px;
  width: 35px;
  -webkit-transition: var(--hover-transition-length);
  transition: var(--hover-transition-length);
  cursor: pointer;
}

.footer-social-container img:hover {
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
}

.footer span {
  margin: 20px 0 20px 0;
}

#footer-imago-logo {
  height: var(--footer-height);
}

#paypal-img {
  width: 60%;
  min-width: 130px;
}

@media only screen and (max-width: 1050px) {
  .footer-column {
    display: none;
    border: none;
    padding: 0;
    margin: 0;
  }
  .footer-mobile {
    display: block;
    margin: 30px 0 30px 0;
    width: 100%;
    padding: 0;
  }
  #footer-mobile > div:first-child {
    display: none;
  }

  .footer-imago-info {
    margin-left: 0;
  }

  .footer-column:first-child {
    min-width: auto;
  }
}

@media only screen and (max-width: 750px) {
.financer-container {
  flex-direction: column;
}

.financer-text {
  margin-left: 0px;
  margin-top: 20px;
}

}
