@import "./config/Variables.css";
html,
body {
  height: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  margin: 0;
  padding: 0;
  background: var(--imago-dark);
}

body a {
  text-decoration: none;
  color: var(--imago-light);
}

body a:hover {
  color: white;
}

#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.App {
  min-height: 65%;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  letter-spacing: 0.5px;
  background: var(--imago-dark);
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

/* HEADER */

header {
  width: inherit;
  z-index: 999;
  position: fixed;
  font-size: 15px;
  letter-spacing: 0.3px;
  background-color: var(--imago-menu-bar);
  font-weight: 600;
  height: var(--menu-bar-height);
  font-family: "IBM Plex Sans Condensed", sans-serif;
}

header img {
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.menu-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: var(--app-width);
  height: var(--menu-bar-height);
  margin: 0 auto;
}

.page-logo {
  height: 100%;
  margin-left: -90px;
}

.page-logo a {
  padding: 0;
}
.page-logo > div {
  -webkit-transition: var(--fade-in-out-transition);
  transition: var(--fade-in-out-transition);
}

#logo-text-image {
  padding: 30px 0 30px 0;
  height: 100%;
}

#logo-moon-image {
  padding: 25px 15px 25px 25px;
}

.hidden {
  opacity: 0;
}

.menu-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.menu-container a {
  color: white;
  line-height: calc(var(--menu-bar-height) - 2 * var(--links-padding));
  padding: var(--links-padding);
  transition: var(--link-transition-length);
  -webkit-transition: var(--link-transition-length);
}

.menu-container .page-logo a:nth-child(1) {
  padding: 0;
}

.menu-container .page-logo a:nth-child(2) {
  padding: 0;
}

.menu-items > a:last-child {
  padding-right: 0px;
}

.menu-container a:hover {
  color: var(--imago-light);
}

.right-side-header {
  display: flex;
  flex-direction: row;
}

.language-container {
  width: 75px;
  margin-right: -75px;
  height: var(--menu-bar-height);
  line-height: var(--menu-bar-height);
  -webkit-transition: var(--fade-in-out-transition);
  transition: var(--fade-in-out-transition);
}

.mobile-language-container.mobile-fake-link {
  transition: var(--fade-in-out-transition);
}

.mobile-language-container {
  justify-content: flex-start !important;
  margin-top: 15px;
}

.mobile-language-container label {
  color: white;
  margin-right: 10px;
  cursor: pointer;
  transition: var(--link-transition-length);
}

.language-container label {
  color: white;
  float: right;
  margin-left: 10px;
  transition: var(--link-transition-length);
}

.selected-language {
  color: var(--imago-light) !important;
}

.language-container label:hover {
  color: var(--imago-light);
  cursor: pointer;
}

.dropdown {
  height: var(--menu-bar-height);
  display: inline-block;
  font-kerning: 30px;
}

.dropdown > a {
  display: inline-block;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.dropdown-content {
  margin-top: -25px;
  display: none;
  position: absolute;
  background-color: var(--imago-menu-bar);
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  color: white;
  line-height: normal;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.imago-menu-item-container {
  display: flex;
  float: left;
}

.imago-menu-item-container img {
  display: block;
  width: 20px;
  margin: auto auto;
  margin-right: 5px;
}

.mobile-icon img {
  margin-left: 5px;
  margin-right: 0;
}

/* CONTENT */

.main-container,
.main-container-mobile {
  width: var(--app-width);
  background: var(--imago-dark);
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: var(--standard-margin);
  color: white;
  padding-top: calc(var(--menu-bar-height) + var(--content-margin-header));
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */

input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */

input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

::-webkit-input-placeholder {
  color: var(--imago-light);
}

:-ms-input-placeholder {
  color: var(--imago-light);
}

::-ms-input-placeholder {
  color: var(--imago-light);
}

::placeholder {
  color: var(--imago-light);
}

@media only screen and (max-width: 1200px) {
  .language-container {
    position: absolute;
    top: 0;
    right: -15px;
    display: flex;
    flex-direction: column;
    line-height: normal;
    padding-top: 30px;
    margin: 0;
  }

  .menu-container a {
    padding-right: 13px;
    padding-left: 13px;
  }

  #logo-text-image {
    padding: 32px 0 32px 0;
  }

  #logo-moon-image {
    padding: 27px 15px 27px 27px;
  }

  .language-container label {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1050px) {
  .main-container,
  .main-container-mobile {
    padding-top: calc(var(--menu-bar-height) + var(--content-margin-header) + var(--extended-bar-height));
  }

  .menu-container:last-child {
    height: var(--extended-bar-height);
    margin: 0 auto;
    position: relative;
  }

  .page-logo {
    height: var(--menu-bar-height);
    margin-left: 0;
  }

  .dropdown {
    display: inline-block;
  }

  .right-side-header {
    margin: 0 auto;
  }

  .menu-container a {
    line-height: normal;
    padding-right: var(--links-padding);
    padding-left: var(--links-padding);
  }

  .dropdown-content > a {
    line-height: normal;
    padding: var(--links-padding);
  }

  .dropdown-content > a:last-child {
    padding-top: var(--links-padding);
  }

  .menu-items > a:first-child {
    padding-left: 0;
  }
  .menu-items > a:last-child {
    padding-right: 0;
  }

  .menu-items > a {
    height: var(--extended-bar-height);
  }

  .dropdown > a {
    height: var(--extended-bar-height);
  }

  .menu-items {
    width: 100%;
    height: var(--extended-bar-height);
    justify-content: space-between;
  }

  .right-side-header {
    width: 100%;
  }

  header {
    height: calc(var(--menu-bar-height) + var(--extended-bar-height));
  }

  .language-container {
    width: auto;
    display: inline-block;
    position: absolute;
    right: 0;
  }
  .language-container label {
    margin-left: 10px;
  }
  #logo-moon-image {
    padding-left: 0px;
  }
}

.drek {
  margin-bottom: 50px;
}

@media only screen and (max-width: 1050px) {
  .dropdown-content {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 750px) {
  .main-container,
  .main-container-mobile {
    padding-top: calc(var(--mobile-menu-bar-height) + var(--standard-margin));
    margin-top: 0;
  }

  header {
    height: var(--mobile-menu-bar-height);
  }
}

@media only screen and (max-width: 500px) {
  .main-container-mobile {
    width: 100%;
  }
  header {
    height: var(--mobile-menu-bar-height);
  }
}

/* MOBILE HEADER */

.mobile-menu-container {
  width: 100%;
  height: var(--mobile-menu-bar-height);
  background: var(--imago-menu-bar);
  position: absolute;
  top: 0;
}

.mobile-menu-header {
  height: 100%;
  width: 100%;
  position: relative;
}

.mobile-menu-content a,
.mobile-fake-link {
  color: white;
  padding: 5px 10px 5px 20px;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
}

.mobile-fake-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#submenu-arrow {
  height: 15px;
}

#mobile-menu-image {
  padding: 20px;
  float: right;
  transition: var(--hover-transition-length);
}

.mobile-menu-content {
  width: 100%;
  overflow: hidden;
  background: var(--imago-menu-bar);
  box-sizing: border-box;
  height: 0;
  display: flex;
  flex-direction: column;
}

.menu-opened {
  height: auto;
  padding: 10px 20px 20px 0;
}

.mobile-dropdown-content {
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  margin: 0px;
}

.submenu-opened {
  height: auto;
  margin: 10px;
}

#mobile-logo-text-image {
  padding: 20px 0 20px 0;
}

#mobile-logo-moon-image {
  padding: 15px;
}
